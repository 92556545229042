<template>
  <Teleport to="body" :disabled="isDisabledTeleport">
    <Transition name="modal">
      <div
        v-if="isShowModal"
        class="modal-container"
        :class="styles"
        @click.self="closeModal"
        v-bind="$attrs"
      >
        <div class="modal">
          <div v-if="isLoading" class="modal-spinner">
            <SpinnerRadial />
          </div>
          <template v-else>
            <div class="modal-header">
              <slot name="header"></slot>
            </div>
            <div class="modal-body">
              <slot></slot>
            </div>
          </template>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script lang="ts">
import { PropType } from "vue";
import SpinnerRadial from "~/components/loaders/SpinnerRadial.vue";

export default defineNuxtComponent({
  components: { SpinnerRadial },
  props: {
    isShowModal: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isLoading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isDisabledTeleport: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    nestedDialog: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close"],
  inheritAttrs: false,
  setup(props, { emit }) {
    const route = useRoute();

    const closeModal = () => emit("close");

    const toggleScrollbar = (value: boolean): void => {
      if (props.nestedDialog) {
        return;
      }

      if (value) {
        document.documentElement.style.setProperty(
          "--scrollbar-compensate",
          `${getScrollbarWidth()}px`
        );
        document.documentElement.classList.add("no-scroll");
      } else {
        document.documentElement.style.removeProperty("--scrollbar-compensate");
        document.documentElement.classList.remove("no-scroll");
      }
    };

    watch(
      () => route.path,
      () => toggleScrollbar(false)
    );

    onMounted(() => {
      toggleScrollbar(true);
    });

    onUnmounted(() => {
      toggleScrollbar(false);
    });

    return {
      closeModal,
    };
  },
});
</script>
